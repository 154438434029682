import * as React from 'react'

import {withStyles, createStyles, Theme} from '@material-ui/core/styles';

const styles = ({palette}: Theme) => createStyles({
  root: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    padding: "1em 5em 2em",
  },

  logoWrapper: {
      display: "flex",
      alignItems: "center",
  },
  logo: {
      marginLeft: "5px",
      flexGrow: 0
  },
  link: {
      textDecoration: "underline",
      flexGrow: 1
  },
});

interface IFooterProps {
    classes: any;
}

class Footer extends React.Component<IFooterProps & React.HTMLAttributes<HTMLDivElement>> {
    render() {
        let {classes} = this.props
        return (
            <div className={`${this.props.classes.root} ${this.props.className}`}>
                Copyright © 2017-2019 zeusleeft.nl
            </div>
        )
    }
}

export default withStyles(styles)(Footer);
