import * as React from 'react';

import {withStyles, createStyles, Theme} from '@material-ui/core/styles';

import Page from '../page/page';

import Banner from './banner'
import Program from './program'

const styles = () => createStyles({

});

interface IProgrammaPageProps {
    classes: any
}

export class ProgrammaPage extends React.Component<IProgrammaPageProps> {
    render() {
        return (
            <Page>
                <Banner />
                <Program />
                <div />
            </Page>
        );
    }
};

export default withStyles(styles)(ProgrammaPage);
