import * as React from 'react';

import {withStyles, createStyles, Theme} from '@material-ui/core/styles';
import ProgramPoint from './programPoint';

const styles = ({palette}: Theme) => createStyles({
    header: {
        color: palette.primary.main
    }
});

interface IProgramProps {
    classes: any
}

export class Program extends React.Component<IProgramProps> {
    render() {
        let {classes} = this.props;
    
        return (
            <div className={classes.header}>
                <h2> Het programma van de politieke partij Zeusleeft </h2>
                <ProgramPoint title="Kies voor Zeus">
                    Als programma punt nummer 1 wil Zeusleeft Nederland bekend maken dat er maar 1 oplossing is: Zeus Volgen!
                </ProgramPoint>
                <ProgramPoint title="Kies voor Olympus">
                Jezusleeft staat naast Olympus. Heel Olympus ,is voor de Goden. De huidige problemen in Griekenland en in Olympus kunnen alleen worden opgelost door
                afspraken te maken en samen te werken met Noorse gesprekspartners die niet uit zijn op de vernietiging van Olympus en het Griekse pantheon.
                </ProgramPoint>
         </div>
        );
    }
};

export default withStyles(styles)(Program);
