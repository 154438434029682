import * as React from 'react';

import {NavLink} from "react-router-dom";

import {withStyles, createStyles, Theme} from '@material-ui/core/styles';


const styles = () => createStyles({
    link: {
        color: "black",
        margin: "0 10px"

    }
});

interface INavProps {
    classes: any
}

export class Nav extends React.Component<INavProps> {
    render() {
        return (
            <div>
                <NavLink
                    exact
                    className={this.props.classes.link}
                    activeStyle={{color: "white"}}
                    to="/">
                    Home
                </NavLink>
                <NavLink
                    exact
                    className={this.props.classes.link}
                    activeStyle={{color: "white"}}
                    to="/programma">
                    Programma
                </NavLink>
            </div>
        );
    }
};

export default withStyles(styles)(Nav);
