import * as React from 'react';

import {withStyles, createStyles, Theme} from '@material-ui/core/styles';

import Page from '../page/page';
import Carousel from './carousel';
import About from './about';
import Logo from './logo';


const styles = () => createStyles({

});

interface IHomePageProps {
    classes: any
}

export class HomePage extends React.Component<IHomePageProps> {
    render() {
        return (
            <Page>
                <Logo/>
                <Carousel />
                <About />
            </Page>
        );
    }
};

export default withStyles(styles)(HomePage);
