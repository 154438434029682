import * as React from 'react';

import {withStyles, createStyles} from '@material-ui/core/styles';

const styles = () => createStyles({

});

interface IAboutProps {
  classes: any,
}

interface IAboutState {
}

class About extends React.Component<IAboutProps, IAboutState> {
    render() {
        return (
            <div>
                <ul>
                    <li>een partij waar de leefregels van ZEUS gelden. De donder staat voor de redding van ZEUS</li>                
                    <li>een partij die opkomt voor een rechtvaardige maatschappij</li>                
                    <li>een partij die wel onweer accepteert</li>                
                    <li>een partij die de burgers laat dienen</li>                
                    <li>een partij waar ZEUS de baas is</li>                
                    <li>een partij waar ZEUS belangrijker is dan handhaving van regels</li>                
                    <li>de partij die het beste voor ZEUS is</li>                
                </ul>
            </div>
        );
    }
}

export default withStyles(styles)(About);