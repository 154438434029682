import * as React from 'react';
import { HashRouter as Router, Route} from "react-router-dom";

import Home from "../home/page";
import Programma from "../programma/page";

export class Index extends React.Component {
    render() {
        return (
            <>
                <Router>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/programma" component={Programma} />
                </Router>
                <div/>
            </>

        );
    }
};

export default Index;
