import * as React from 'react';

import { withStyles, createStyles, Theme } from '@material-ui/core/styles';

const img = '/images/banner.png';

const styles = ({ palette, typography }: Theme) => createStyles({
    root: {
        width: "100%",
        height: "70vh",
    },

    image: {
        display: "block",
        margin: "auto",
        maxHeight: "50vh"
    }
});

interface IBannerProps {
    classes: any,
    imagePath?: string
}

interface IBannerState {
}

class Banner extends React.Component<IBannerProps, IBannerState> {
    render() {
        return (
            <div className={`${this.props.classes.root}`}>
                <img
                    className={`${this.props.classes.image}`}
                    src={this.props.imagePath || img} />
            </div>
        );
    }
}

export default withStyles(styles)(Banner);