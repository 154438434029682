import * as React from 'react';

import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import {withStyles, createStyles, Theme} from '@material-ui/core/styles';

const styles = ({palette, typography}: Theme) => createStyles({
  root: {
    minHeight: "50vh",
  },
  textBar: {
    backgroundColor: palette.primary.main,
    color: palette.primary.contrastText,
    height: "25px",
    textAlign: "center",
    padding: "5% 0 10% 0",
    margin: "0 10% 0 10%",
    fontSize: typography.h3.fontSize
  },

  textBarText: {
    color: palette.primary.contrastText,
    fontSize: typography.h6.fontSize
  },

  stepper: {
    backgroundColor: palette.primary.main,
    margin: "0 10% 0 10%",
  }
});

interface ICarouselProps {
  classes: any,
  texts?: string[],
}

interface ICarouselState {
  activeStep?: number
}

class Carousel extends React.Component<ICarouselProps, ICarouselState> {
    constructor(props: any){
        super(props);
        this.state = { activeStep: 0 };
    }

    static defaultProps = {
        texts: ["ZEUS IS DE OPLOSSING", "ZEUS KIEST VOOR JOUW, VOOR WIE KIES JIJ?"],
    }

    handleNext = () => {
        let nextStep = this.state.activeStep + 1;

        if (nextStep > this.props.texts.length-1) {
            nextStep = 0;
        }

        this.setState(() => ({
            activeStep: nextStep
        }));
    };

    handleBack = () => {
        let nextStep = this.state.activeStep - 1;
        if (nextStep < 0) {
            nextStep = this.props.texts.length-1;
        }

        this.setState(() => ({
            activeStep: nextStep
        }));
    };

  render() {
    let {classes} = this.props
    return (
      <div className={classes.root}>
        <div
          className={classes.textBar}
          color="primary">
          ZEUSLEEFT.NL
          <br />
          <span className={classes.textBarText}>
            {this.getTextForCurrentStep()}
          </span>
        </div>
        <MobileStepper
          className={classes.stepper}
          variant="dots"
          steps={this.props.texts.length}
          position="static"
          activeStep={this.state.activeStep}
          nextButton={
            <Button size="small" onClick={this.handleNext}>
              {<KeyboardArrowRight />}
            </Button>
          }
          backButton={
            <Button size="small" onClick={this.handleBack}>
              {<KeyboardArrowLeft />}
            </Button>
          }
        />
      </div>
    );
  }

  getTextForCurrentStep(): string {
    return this.props.texts[this.state.activeStep]
  }
}

export default withStyles(styles)(Carousel);