import * as React from 'react';

import {withStyles, createStyles, Theme} from '@material-ui/core/styles';

const img = '/images/logo.png';

const styles = ({palette, typography}: Theme) => createStyles({
    root: {
        width: "100%",
    },
    image: {
        display:"block",
        margin:"auto",
        maxHeight: "20vh"
    }
});

interface ILogoProps {
  classes: any,
  imagePath?:string
}

interface ILogoState {
}

class Logo extends React.Component<ILogoProps, ILogoState> {
    render() {
        return (
            <div className={`${this.props.classes.root}`}>
            <img
                className={`${this.props.classes.image}`}
                src={this.props.imagePath || img} />
             </div>
        );
    }
}

export default withStyles(styles)(Logo);