import * as React from 'react';

import { withStyles, createStyles, Theme } from '@material-ui/core/styles';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = ({ palette, typography }: Theme) => createStyles({
    root: {
        margin: "15px" 
    },
    header: {
        color: palette.primary.main,
        fontSize: typography.h5.fontSize
    }
});

interface IProgramPointProps {
    classes: any,
    title: string,
    children: string
}

export class ProgramPoint extends React.Component<IProgramPointProps> {
    render() {
        let { classes, title, children } = this.props;

        return (
            <div className={classes.root}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography className={classes.header}>{title}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Typography>
                            {children}
                        </Typography>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
        );
    }
};

export default withStyles(styles)(ProgramPoint);
